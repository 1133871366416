import { Link } from "react-router-dom";
import "./CustomCategories.css";
import { useContext } from "react";
import { ConfigContext } from "../../../../assets/contexts/configContext";
import { CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT } from "../../../../assets/utils/constants";
import { buildCategoryPath } from "../../../../assets/utils/utils";

const usedTranslitNames = [
    "67c5f6a15afb998e82c5e094", // Песах // ПО ЗАПРОСУ 04.03.2025
    "6696737eac14d0f80b4b4ab9", // Акция 3+1
    "669671b6ac14d0f80b4b470b", // Новое поступление
    "669671d9ac14d0f80b4b4763", // Товары OSEM
    "66967215ac14d0f80b4b47d2", // Кофе Elite
    "669671dbac14d0f80b4b476f", // Бакалея
    "669673d0ac14d0f80b4b4ba7", // Мясо, полуфабрикаты, колбасы
    "66967528ac14d0f80b4b4ea9", // Соки, напитки
    "669671b6ac14d0f80b4b470d", // Печенье, сладости
    "669673caac14d0f80b4b4b8a", // Молочные продукты
    "669671aaac14d0f80b4b46f0", // Сдоба, маца, хлеб
    "66967313ac14d0f80b4b4a2c", // Пицца и суши
    "669675d8ac14d0f80b4b50c8", // Рыба и икра
    "669671b5ac14d0f80b4b4704", // Кофе и чай
    "669671c7ac14d0f80b4b474d", // Халва, кексы
    "66967279ac14d0f80b4b4896", // Алкоголь
    "66967457ac14d0f80b4b4ccc", // Без глютена
    "669672b9ac14d0f80b4b48f4", // Израильская косметика
    "66f3f4c9bce452059b6ddd38", // Сухофрукты и орехи
    // "66967564ac14d0f80b4b4f62", // Рош а-Шана // СКРЫТ ПО ЗАПРОСУ 14.10.2024
];


function CustomCategories() {
    const config = useContext(ConfigContext);

    const { initialConstants, cardTokens } = config
    const { FIRST_LEVEL_CATEGORIES } = initialConstants
    const { image_radius } = cardTokens;

    const filteredItems = [...FIRST_LEVEL_CATEGORIES.filter(item => usedTranslitNames.includes(item._id))];

    const sortItems = (items, order) => {
        const orderMap = new Map(order.map((name, index) => [name, index]));
        return items.filter(item => orderMap.has(item._id))
            .sort((a, b) => orderMap.get(a._id) - orderMap.get(b._id));
    };

    const sortedItems = sortItems(filteredItems, usedTranslitNames);

    return (
        <div className={`custom-categories`} >
            {sortedItems && sortedItems.length > 0 ?
                sortedItems.map((item, i) => (
                    <Link className="custom-categories__item"
                        to={`/${CATALOG_MAIN_LINK}/${item.translit_name}`}
                        key={item.translit_name}
                    >
                        {console.log(item)}
                        <img
                            className="custom-categories__img"
                            src={item.file ? item.file.urls.orig : IMG_PLACEHOLDER_PRODUCT}
                            alt={item.name}
                            style={{
                                borderRadius: image_radius,
                            }}
                        />
                        {/* {item.name} */}
                    </Link>
                ))
                :
                null}
        </div >
    );
}

export default CustomCategories;